import { FunctionComponent } from 'react'
import { useTranslation } from '../../context/ConfigContext'
import { UseFormRegister } from 'react-hook-form'
import { civilities } from '../../../data/civility'

export const Civility: FunctionComponent<{ register: UseFormRegister<any>; name: string }> = ({
  register,
  name,
}) => (
  <select {...register(name)}>
    <CivilityOptions />
  </select>
)

export const CivilityOptions: FunctionComponent = () => {
  const t = useTranslation()

  return (
    <>
      {civilities.map((option) => (
        <option key={option} value={t(option ?? '')}>
          {t(option ?? '') || t('product_alert_popup_civility_placeholder', {}, false)}
        </option>
      ))}
    </>
  )
}
