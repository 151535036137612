'use client'

import { useTranslation } from '../../context/ConfigContext'

interface TagProps {
  isExcluWeb: number
  isNew: number
  isIconique: number
}

export const Tag = (props: TagProps) => {
  const t = useTranslation()

  return (
    <>
      {/* Exclu Web > New > Iconique */}
      {props.isExcluWeb === 1 ? (
        <p className='push-tag'>{t('catalog-listing-exclu-web', {}, true)}</p>
      ) : // isExcluWeb !== 1
      props.isNew === 1 ? (
        <p className='push-tag'>{t('catalog-listing-new', {}, true)}</p>
      ) : // isExcluWeb && isNew !== 1
      props.isIconique === 1 ? (
        <p className='push-tag'>{t('catalog-listing-iconique', {}, true)}</p>
      ) : null}
    </>
  )
}
