'use client'

import { FunctionComponent, useContext, useMemo, useState } from 'react'

import { ProductHitAlgolia } from '../../../../queries/algolia/ResultFetchAlgoliaByQuery.types'
import { CartItem as CartItemType } from '../../../domain/cart'

import { findVariant, getIsBraceletMyMove } from '../../utils/Product.utils'
import {
  formatPriceByStoreAndLocale,
  formatPriceFromAlgoliaConfigPriceFormat,
  formatPriceToShowLocal,
  getPriceFromProductHitAlgolia,
} from '../../utils/price.utils'
import { useIsMobile } from '../../utils/screen.utils'
import { getIsArabicLocale } from '../../utils/locale.utils'
import { Tag } from '../Tag/tag'
import CartItemBraceletMyMoveContainer from './CartItemBraceletMyMoveContainer'
import CartItemBundleMyMoveContainer, {
  getValuesFromBundleCartItem,
} from './CartItemBundleMyMoveContainer'
import CartItemConfigurableProduct from './CartItemConfigurableProduct'
import CartItemSimpleProduct from './CartItemSimpleProduct'
import { getImageToShowCartItem } from './CartItem.utils'
import getIsItemStillValidOnCart from '../CheckoutWorkflow/getIsItemStillValidOnCart'
import { AlgoliaConfigContext } from '../AlgoliaConfigContext/AlgoliaConfigContextProvider'
import { getSelectedVariantsForBundleProduct } from '../Product/ProductConfigurationBundleProduct'
import ProductGetNotifyInStock from '../Product/ProductGetNotifyInStock'
import { Button } from '../Button/Button'
import { ConfigContext, useTranslation } from '../../context/ConfigContext'
import Image from '../Image/Image'
import Link from '../Link/Link'
import Loader from '../Loader/Loader'
import useCurrentLocale from '../../../core/hook/useCurrentLocale'

export const CartItemForHeaderSearch = ({ product }: { product: ProductHitAlgolia }) => {
  const { storeConfig } = useContext(ConfigContext)
  const { algoliaConfig } = useContext(AlgoliaConfigContext)
  // eslint-disable-next-line no-underscore-dangle
  const description =
    product._highlightResult.categories_without_path
      ?.map((category) => category.value)
      .join(', ') ?? ''
  const t = useTranslation()

  const isExcluWeb: number = product.is_exclu_web === t('catalog-listing-exclu-web') ? 1 : 0
  const isIconique: number = product.is_iconique === t('catalog-listing-iconique') ? 1 : 0
  const isNew: number = product.is_new === true ? 1 : 0

  const price = getPriceFromProductHitAlgolia(product) ?? 0
  const formattedPrice = algoliaConfig
    ? formatPriceFromAlgoliaConfigPriceFormat(price, algoliaConfig)
    : price

  return (
    <article className='cart-item item-small'>
      <picture>
        <Image src={product.small_image} alt={product.name} size='s' />
        <Tag isExcluWeb={isExcluWeb} isNew={isNew} isIconique={isIconique} />
      </picture>
      <div className='content'>
        <h5
          className='title-h5'
          dangerouslySetInnerHTML={{ __html: product._highlightResult.name.value }}
        />
        <p dangerouslySetInnerHTML={{ __html: description }} className='cart-item-descgrey' />
        {storeConfig.display_prices === 1 && (
          <div className='content-footer'>
            <p className='price'>{formattedPrice}</p>
          </div>
        )}
      </div>
    </article>
  )
}

const CartItemFormByTypename = ({
  item,
  onUpdate,
}: {
  item: CartItemType
  onUpdate: (updating: boolean) => void
}) => {
  // Bundle My Move Product
  if (item.product.__typename === 'BundleProduct') {
    return <CartItemBundleMyMoveContainer item={item} onUpdate={onUpdate} />
  }

  if (item.product.__typename === 'ConfigurableProduct') {
    // Bracelet My Move Product
    if (getIsBraceletMyMove(item.product)) {
      return <CartItemBraceletMyMoveContainer item={item} onUpdate={onUpdate} />
    }

    // Configurable Product
    return <CartItemConfigurableProduct item={item} onUpdate={onUpdate} />
  }

  // Simple Product (only carving)
  return <CartItemSimpleProduct item={item} onUpdate={onUpdate} />
}

const CartItemMyMoveProductDetails = ({ item }: { item: CartItemType }) => {
  const { myMoveColor, braceletMyMoveColor, braceletMyMoveSize } = getValuesFromBundleCartItem(item)
  if (!myMoveColor || !braceletMyMoveColor || !braceletMyMoveSize) {
    return null
  }

  const selectedVariantsForBundleMyMove = getSelectedVariantsForBundleProduct(
    item.product,
    myMoveColor,
    braceletMyMoveColor,
    braceletMyMoveSize,
  )

  if (!selectedVariantsForBundleMyMove) {
    return null
  }

  const [myMoveVariant, braceletMyMoveVariant] = selectedVariantsForBundleMyMove

  if (!myMoveVariant || !braceletMyMoveVariant) {
    return null
  }

  return (
    <div>
      <p>
        {item.product.items?.[0].options?.[0]?.product?.configurable_options?.[0].label} :{' '}
        {myMoveVariant.attributes?.[0]?.label}
      </p>

      <p>
        {item.product.items?.[1].options?.[0]?.product?.configurable_options?.[0].label} :{' '}
        {braceletMyMoveVariant.attributes?.[0]?.label}
      </p>
      <p>
        {item.product.items?.[1].options?.[0]?.product?.configurable_options?.[1].label} :{' '}
        {braceletMyMoveVariant.attributes?.[1]?.label}
      </p>
    </div>
  )
}

export const CartItemSmall = ({
  item,
  allowRemove = true,
  onRemove,
}: {
  item: CartItemType
  allowRemove: boolean
  onRemove: () => void
}) => {
  const { storeConfig } = useContext(ConfigContext)
  const imageToShow = getImageToShowCartItem(item)
  const t = useTranslation()
  const currentLocale = useCurrentLocale()
  const isArabicLocale = getIsArabicLocale(currentLocale)

  const isItemStillValidOnCart = useMemo(() => getIsItemStillValidOnCart(item), [item])

  const formatedPrice = formatPriceByStoreAndLocale(currentLocale, item.prices ?? undefined)

  return (
    <article className='cart-item item-small'>
      <Link className='container-picture' href={item.product.url_key ?? ''}>
        <picture>
          <Image src={imageToShow?.messika_mid_thumbnail} alt={item.product.name} size='s' />
        </picture>
      </Link>
      <div className='content'>
        <Link href={item.product.url_key ?? ''}>
          <h5 className='title-h5'>{item.product.name}</h5>
        </Link>
        <div className='infos-cart'>
          {item.product.id === 3006 ? (
            <div className='infos-cart'>
              <ul className='content-infos bracelet'>
                <li>
                  <p>{item.product.title}</p>
                </li>
                {item.configurable_options &&
                  item.configurable_options.map((option) => (
                    <li key={option.value_id}>
                      <p>
                        {option.option_label} : {option.value_label}
                      </p>
                    </li>
                  ))}

                {item.customizable_options?.map((option) =>
                  option.values?.map((item) => (
                    <li key={item.value}>
                      <p>
                        {t('Couleur de la boucle', {}, true)} : {item.label}
                      </p>
                    </li>
                  )),
                )}
              </ul>
            </div>
          ) : (
            <div className='content-infos'>
              {item.product.title && <span>{item.product.title}</span>}
              {item.configurable_options && (
                <span>
                  {item.configurable_options.map(
                    (option) => `${option.option_label} ${option.value_label}`,
                  )}
                </span>
              )}
            </div>
          )}

          {item.product.__typename === 'BundleProduct' && (
            <div className='bundleProduct'>
              <CartItemMyMoveProductDetails item={item} />
            </div>
          )}

          {item.engraving_options?.has_engraving && (
            <p className='engraving-option'>{t('cart-engraving-confirmation-text', {}, true)}</p>
          )}

          {/* Modal cart (allowRemove) : show quantity if quantity>1 / recap cart : always show quantity */}
          {isArabicLocale ? (
            <div className='flex quantity'>
              {item.quantity} {t('Quantity:', {}, true)}
            </div>
          ) : allowRemove ? (
            <>
              {item.quantity > 1 && (
                <div className='flex quantity'>
                  {t('Quantity:', {}, true)} {item.quantity}
                </div>
              )}
            </>
          ) : (
            <div className='flex quantity'>
              {t('Quantity:', {}, true)} {item.quantity}
            </div>
          )}

          <div className='content-footer'>
            {storeConfig.display_prices === 1 && (
              <p className='price'>
                {formatPriceToShowLocal(
                  formatedPrice.value,
                  formatedPrice.currency,
                  currentLocale,
                ) ?? t('tunnel-packaging-image-subtitle', {}, true)}
              </p>
            )}

            {allowRemove && formatedPrice.value > 0 && (
              <button type='button' className='remove-button' onClick={onRemove}>
                {t('Remove', {}, true)}
              </button>
            )}
          </div>
        </div>

        {!isItemStillValidOnCart && (
          <p className='cart-item-error'>{t('This product is out of stock.', {}, true)}</p>
        )}
      </div>
    </article>
  )
}

type CartItemProps = {
  item: CartItemType
  onRemove: () => void
}

const CartItem: FunctionComponent<CartItemProps> = ({ item, onRemove }) => {
  const isMobile = useIsMobile()
  const { storeConfig } = useContext(ConfigContext)
  const imageToShow = getImageToShowCartItem(item)
  const [isOnUpdate, setOnUpdate] = useState<boolean>(false)

  const t = useTranslation()
  const currentLocale = useCurrentLocale()
  const isArabicLocale = getIsArabicLocale(currentLocale)

  const isItemStillValidOnCart = useMemo(() => getIsItemStillValidOnCart(item), [item])
  const priceMobile = item.prices?.row_total.value || 0

  let selectedVariant: Variant | null = null
  const attributeCode = item.product.configurable_options?.[0]?.attribute_code
  if (attributeCode) {
    selectedVariant = findVariant(item.product, [
      {
        code: attributeCode,
        value: item.configurable_options[0].value_id,
      },
    ])
  }

  const formatedPrice = formatPriceByStoreAndLocale(currentLocale, item.prices ?? undefined)

  if (isMobile) {
    return (
      <article className={`cart-item cart-item-mobile cart-item-id-${item.id}`}>
        <div className='head'>
          <Link href={item.product.url_key ?? ''}>
            <picture>
              <Image src={imageToShow?.messika_mid_thumbnail} alt={item.product.name} size='s' />
            </picture>
          </Link>
          <div className='content'>
            {priceMobile > 0 && (
              <button type='button' className='remove-button' onClick={onRemove}>
                {t('Remove', {}, true)}
              </button>
            )}

            <div className='title-left'>
              <Link href={item.product.url_key ?? ''}>
                <h5 className='title-h5'>{item.product.name}</h5>
              </Link>

              <p>{item.product.title}</p>

              {storeConfig.display_prices === 1 && (
                <p className='price'>
                  {formatPriceToShowLocal(
                    formatedPrice.value,
                    formatedPrice.currency,
                    currentLocale,
                  ) ?? t('tunnel-packaging-image-subtitle', {}, true)}
                </p>
              )}
              {!isItemStillValidOnCart && (
                <span className='cart-item-outofstock-label'>{t('Out of Stock', {}, true)}</span>
              )}

              {isArabicLocale ? (
                <div className='flex quantity'>
                  {item.quantity} {t('Quantity:', {}, true)}
                </div>
              ) : (
                <div className='flex quantity'>
                  {t('Quantity:', {}, true)} {item.quantity}
                </div>
              )}
            </div>
          </div>
        </div>
        <form className='cart-item-form'>
          <CartItemFormByTypename
            item={item}
            onUpdate={(updating: boolean) => setOnUpdate(updating)}
          />
        </form>
        {item.engraving_options?.has_engraving && (
          <div className='cart-item-engraving'>
            <p className='small carving-msg'>
              {t('Expect an additional delay of 5 days.', {}, true)}
            </p>
          </div>
        )}
        {!isItemStillValidOnCart && (
          <div className='cart-item-outofstock-buttons'>
            <ProductGetNotifyInStock product={selectedVariant?.product || item.product} />
            <Button
              classname='small'
              label={t('Retirer du panier', {}, false)}
              onClick={onRemove}
            />
          </div>
        )}
      </article>
    )
  }

  return (
    <article className={`cart-item cart-item-id-${item.id}`}>
      <Link href={item.product.url_key ?? ''}>
        <picture>
          <Image src={imageToShow?.thumbnail} alt={item.product.name} size='s' />
        </picture>
      </Link>
      <div className='content'>
        <div className='head'>
          <div className='title-left'>
            <Link className='flex' href={item.product.url_key ?? ''}>
              <h5 className='title-h5'>{item.product.name}</h5>
              {!isItemStillValidOnCart && (
                <span className='cart-item-outofstock-label'>{t('Out of Stock', {}, true)}</span>
              )}
            </Link>

            <p>{item.product.title}</p>

            {isArabicLocale ? (
              <div className='flex quantity'>
                {item.quantity} {t('Quantity:', {}, true)}
              </div>
            ) : (
              <div className='flex quantity'>
                {t('Quantity:', {}, true)} {item.quantity}
              </div>
            )}
          </div>
          <div className='title-right'>
            {storeConfig.display_prices === 1 && (
              <p className='price'>
                {formatPriceToShowLocal(
                  formatedPrice.value,
                  formatedPrice.currency,
                  currentLocale,
                ) ?? t('tunnel-packaging-image-subtitle', {}, true)}
              </p>
            )}
            {formatedPrice.value > 0 && (
              <button
                type='button'
                className='remove-button'
                aria-label={t('remove product', {}, false)}
                onClick={onRemove}>
                {t('Remove', {}, true)}
              </button>
            )}
          </div>
        </div>
        <form className='cart-item-form'>
          <CartItemFormByTypename item={item} onUpdate={(updating) => setOnUpdate(updating)} />
          {item.engraving_options?.has_engraving && (
            <p className='small carving-msg'>
              {t('Expect an additional delay of 5 days.', {}, true)}
            </p>
          )}
        </form>
        {!isItemStillValidOnCart && (
          <div className='cart-item-outofstock-buttons'>
            <ProductGetNotifyInStock product={selectedVariant?.product || item.product} />
            <Button classname='small' label='Retirer du panier' onClick={onRemove} />
          </div>
        )}
      </div>
      {isOnUpdate && (
        <div className='loader'>
          <Loader />
        </div>
      )}
    </article>
  )
}

export default CartItem
