'use client'

import { useContext } from 'react'
import { ModalContext, ModalContextType } from '../../context/ModalContext'
import { Button } from '../Button/Button'
import ModalCarving from '../Modal/ModalCarving'
import { CartItem as CartItemType } from '../../../domain/cart'
import useUpdateCarvingOfCartItem from '../../../core/hook/cartHooks/useUpdateCarvingOfCartItem'
import { useTranslation } from '../../context/ConfigContext'
import Icon from '../Icon/Icon'
import { Icons } from '../Icon/constants'

const CartItemCarving = ({
  item,
  onChange,
}: {
  item: CartItemType

  onChange: (value?: Carving) => void
}) => {
  const { addModal, removeModal } = useContext(ModalContext) as ModalContextType
  const updateCarving = useUpdateCarvingOfCartItem()
  const t = useTranslation()

  return (
    <>
      {item.product.gravure && !item.engraving_options?.has_engraving && (
        <Button
          style='light'
          classname='small'
          onClick={() => {
            const uuid = crypto.randomUUID()
            addModal({
              uuid,
              component: (
                <ModalCarving
                  uuid={uuid}
                  product={item.product}
                  onConfirm={(value: Carving) => {
                    onChange(value)
                    removeModal(uuid)
                  }}
                  onRemove={() => {
                    removeModal(uuid)
                  }}
                />
              ),
            })
          }}
          label={t('Add engraving', {}, false)}
        />
      )}
      {item.engraving_options?.has_engraving && (
        <div className='cart-item-engraving'>
          <div className='form-input'>
            <label className={`typo-${item.engraving_options.engraving_font}`} htmlFor='carving'>
              {t('faq-entretien-messika-title-1', {}, true)}
            </label>
            <div className='form-input-carving'>
              <input
                className={`carving ${item.engraving_options.engraving_font}`}
                disabled
                value={item.engraving_options.engraving_text}
              />
              <button
                type='button'
                onClick={() => {
                  const uuid = crypto.randomUUID()
                  addModal({
                    uuid,
                    component: (
                      <ModalCarving
                        uuid={uuid}
                        product={item.product}
                        onConfirm={(value: Carving) => {
                          onChange(value)
                          removeModal(uuid)
                        }}
                        onRemove={() => {
                          onChange()
                          removeModal(uuid)
                          updateCarving(item)
                        }}
                        initialValue={{
                          text: item.engraving_options?.engraving_text ?? '',
                          font: item.engraving_options?.engraving_font ?? '',
                        }}
                      />
                    ),
                  })
                }}>
                <Icon iconName={Icons.PENCIL} />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CartItemCarving
