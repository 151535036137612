'use client'

import { FunctionComponent, MouseEventHandler, ReactNode, useContext } from 'react'
import useCurrentLocale from '../../../core/hook/useCurrentLocale'
import { ModalContext, ModalContextType } from '../../context/ModalContext'

type LinkProps = {
  href: string
  children: ReactNode
  className?: string
  title?: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
  tabIndex?: number
  dataCategory?: string
  ariaLabel?: string
}
const Link: FunctionComponent<LinkProps> = ({
  href,
  children,
  className,
  title,
  onClick,
  tabIndex,
  dataCategory,
  ariaLabel,
}) => {
  const { clearModals } = useContext(ModalContext) as ModalContextType

  const locale = useCurrentLocale()

  function cleanUrl(url) {
    let newUrl = ''
    switch (true) {
      case url.startsWith('/' + locale + '/'):
        newUrl = url.replace(/^\/[^/]+\//, '')
        break
      case url.startsWith(locale + '/'):
        newUrl = url.replace(/^.+\//, '')
        break

      default:
        newUrl = url.replace(/^\/+/, '')
        break
    }
    return newUrl
  }

  function finalUrl(href) {
    if (href.startsWith('http')) {
      return href
    } else {
      const url = cleanUrl(href) || ''
      const newLink = '/' + locale + '/' + url
      return newLink
    }
  }

  const link = finalUrl(href)

  // TODO: TEMPORATY SOLUCTION BECAUSE RSC HAS NO CACHE WHEN USER CLICK ON LINK
  return (
    <a
      href={link}
      className={className}
      title={title}
      onClick={(e) => {
        onClick && onClick(e)
        clearModals()
      }}
      tabIndex={tabIndex}
      data-category={dataCategory}
      aria-label={ariaLabel}>
      {children}
    </a>
  )
  // return (
  //   <NextLink
  //     href={link}
  //     className={className}
  //     prefetch={prefetch}
  //     title={title}
  //     onClick={(e) => {
  //       onClick && onClick(e)
  //       clearModals()
  //     }}
  //     locale={locale}
  //     tabIndex={tabIndex}>
  //     {children}
  //   </NextLink>
  // )
}

export default Link
