import { CartItemPrices } from '../../../.mesh'
import { ProductHitAlgolia } from '../../../queries/algolia/ResultFetchAlgoliaByQuery.types'
import { useTranslation } from '../context/ConfigContext'
import { AlgoliaConfig } from './AlgoliaConfigContext.utils'
import { getIsUSALocale } from './locale.utils'

export const formatLocal = (local) => {
  switch (local) {
    case 'fr':
    case 'en':
      return 'fr-FR'
    case 'de':
      return 'de-DE'
    case 'es':
      return 'es-ES'
    case 'uk_en':
      return 'en-GB'
    case 'us_en':
      return 'en-US'
    case 'su_en':
      return 'en-CH'
    case 'su_fr':
      return 'fr-CH'
    case 'ar_ar':
    case 'ar_en':
      return 'ar-AE'
    case 'jp_ja':
      return 'ja-JP'
    case 'ch_cn':
      return 'zh-CN'
    case 'hk_en':
      return 'zh-HK'
    case 'ru_en':
    case 'ru_ru':
      return 'ru-RU'
    case 'tu_en':
      return 'tr-TR'
    case 'kr_kr':
      return 'ko'
    default:
      return 'fr-FR'
  }
}

export const formatPriceToShow = (price: number, currency: string): string =>
  Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    maximumFractionDigits: Number.isInteger(price) ? 0 : 2,
  }).format(price)

export const formatPriceByStoreAndLocale = (locale: string, prices?: CartItemPrices): Money => {
  if (getIsUSALocale(locale)) {
    return {
      value: prices?.price.value ?? 0,
      currency: prices?.price.currency ?? 'USD',
    }
  }

  return {
    value: prices?.price_including_tax.value ?? 0,
    currency: prices?.price_including_tax.currency ?? 'USD',
  }
}

export const formatPriceToShowLocal = (price: number, currency: string, local: string): string => {
  const t = useTranslation()
  if (price === 0) {
    return t('Offered')
  }
  return Intl.NumberFormat(formatLocal(local), {
    style: 'currency',
    currency,
    maximumFractionDigits: Number.isInteger(price) ? 0 : 2,
  }).format(price)
}

export const getPriceFromProductHitAlgolia = (product: ProductHitAlgolia) => {
  if (!product.price) {
    return null
  }

  const firstPriceByCurrency = Object.values(product.price)?.[0]

  if (!firstPriceByCurrency) {
    return 0
  }

  return firstPriceByCurrency.default
}

export const formatPriceFromAlgoliaConfigPriceFormat = (
  price: number,
  algoliaConfig: AlgoliaConfig,
) => {
  const { groupSymbol, pattern } = algoliaConfig.priceFormat

  // format price with group symbol
  // ex: 91400 becomes 91.400 en uk_en
  // https://stackoverflow.com/questions/2901102/how-to-format-a-number-with-commas-as-thousands-separators
  const priceWithGroupSymbol = price.toString().replace(/\B(?=(\d{3}){1}(?!\d))/g, groupSymbol)

  // format price with pattern
  // ex: 91.400 becomes £91.400 en uk_en
  const priceWithPattern = pattern.replace('%s', priceWithGroupSymbol)

  return priceWithPattern
}
